import Cookies from 'js-cookie';
import { v7 as uuid } from 'uuid';

/**
 * Sets the tokens in browser cookies
 * @returns The clientID
 */
export const handleAnalyticsID = () => {
	const cookieName = `_gal_clientID`;
	let clientID = Cookies.get(cookieName);

	if (!clientID) {
		clientID = uuid();
		const path = `/`;
		const domain = '.galadise.com';
		Cookies.set(cookieName, clientID, {
			expires: 180,
			path,
			sameSite: 'Strict',
			secure: true,
			domain
		});
	}

	return clientID;
};
