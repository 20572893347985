import React from "react";
import { useState } from "react";
import { Tooltip, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

const Root = styled("div")(({ theme }) => ({
  width: 35,
  height: 35,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  transition: "opacity 0.2s",
  borderRadius: "50%",
  "&:hover": {
    opacity: 0.6,
  },
  "&:active": {
    opacity: 0.3,
  },
}));
const Loader = styled(CircularProgress)(({ theme }) => ({
  width: "15px !important",
  height: "15px !important",
}));

function IconButton({ onClick, tooltip, loaderProps, ...props }) {
  const [loading, setLoading] = useState(false);

  /**
   * Wrapper function to handle loading state
   */
  const handleClick = async e => {
    setLoading(true);
    await onClick(e);
    setLoading(false);
  };

  return (
    <Tooltip title={tooltip}>
      <Root onClick={handleClick} {...props}>
        {!loading && props.children}
        {loading && <Loader {...loaderProps} />}
      </Root>
    </Tooltip>
  );
}

export default IconButton;
