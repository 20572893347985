import React from 'react'
import RevealAnimate from './RevealAnimate'
import { Typography } from '@mui/material'

const TextReveal = ({ children, direction, delay = 0, rootSx, ...props }) => {
	return (
		<RevealAnimate children={children} direction={direction} delay={delay} rootSx={rootSx}>
			<Typography {...props}>{children}</Typography>
		</RevealAnimate>
	)
}

export default TextReveal
