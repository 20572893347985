import React from "react";
import { useState } from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import GalleryImage from "./GalleryImage";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import SelectedImageModal from "./SelectedImageModal";

const Root = styled(Grid)(({ theme }) => ({
  width: "100%",
  padding: "3rem 1.5rem 2rem 1.5rem",
  display: "flex",
  flexWrap: "wrap",
  flex: 1,
}));

function GridGallery({ images }) {
  const theme = useTheme();

  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const largeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const [selectedImage, setSelectedImage] = useState(undefined);

  const calculateCombinedRatio = (start, count) => {
    return Array.from({ length: count })
      .map((_, i) => images[start + i]?.ratio || 0)
      .reduce((acc, ratio) => acc + ratio, 0);
  };

  const calcImageWidth = index => {
    const image = images[index];

    if (mobileScreen) {
      return "100%";
    }

    const imagesPerRow = largeScreen ? 3 : 2;
    const rowIndex = Math.floor(index / imagesPerRow);
    const rowStart = rowIndex * imagesPerRow;

    let combinedRatio = calculateCombinedRatio(rowStart, imagesPerRow);

    const lastRowIndex = Math.floor((images?.length - 1) / imagesPerRow);

    if (rowIndex === lastRowIndex && images?.length % imagesPerRow !== 0) {
      const blankSpaces = imagesPerRow - (images?.length % imagesPerRow);
      const lastRowAverageRatio =
        images
          .slice(rowStart)
          .reduce((acc, img) => acc + (img?.ratio || 0), 0) /
        (imagesPerRow - blankSpaces);
      combinedRatio += blankSpaces * lastRowAverageRatio;
    }

    const widthPercentage = (image?.ratio / combinedRatio) * 100;
    console.log(`Calculated ratio for image ${index}: ${widthPercentage}%`);

    return `${widthPercentage}%`;
  };

  return (
    <Root>
      {images?.map((image, index) => (
        <GalleryImage
          src={image.src}
          fallback={image.fallback}
          key={index}
          width={calcImageWidth(index)}
          ratio={image?.ratio}
          onClick={() => setSelectedImage(image)}
        />
      ))}
      <SelectedImageModal
        image={selectedImage}
        open={!!selectedImage}
        onClose={() => setSelectedImage(undefined)}
      />
    </Root>
  );
}

export default GridGallery;
