import React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Skeleton from './Skeleton';

const PrimaryImage = styled('img')(({ theme }) => ({
	width: 'inherit',
	maxWidth: 'inherit',
	height: 'inherit',
	maxHeight: 'inherit',
	borderRadius: 'inherit'
}));

const ImagePlaceholder = styled('img')(({ theme }) => ({
	width: 'inherit',
	maxWidth: 'inherit',
	height: 'inherit',
	maxHeight: 'inherit',
	borderRadius: 'inherit'
}));

const Image = ({ src, fallback, onLoaded, ...props }) => {
	const [imageLoaded, setImageLoaded] = useState(false);

	const handleOnLoaded = !!onLoaded ? onLoaded : () => {};

	return (
		<>
			{!imageLoaded && !fallback && <Skeleton />}
			{!imageLoaded && !!fallback && <ImagePlaceholder src={fallback} {...props} />}
			<PrimaryImage
				src={src}
				sx={{ display: !imageLoaded ? 'none' : '' }}
				onLoad={() => {
					setImageLoaded(true);
					handleOnLoaded();
				}}
				{...props}
			/>
		</>
	);
};

export default Image;
