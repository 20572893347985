import React from "react";
import { styled } from "@mui/material/styles";
import { ReactComponent as Share } from "../../../resources/svg/share.svg";
import { ReactComponent as Download } from "../../../resources/svg/download.svg";
import IconButton from "../Buttons/IconButton";

const DownloadIcon = styled(Download)(({ theme }) => ({
  width: 22,
  height: 22,
  stroke: `${theme.palette.primary.main} !important`,
}));

const ShareIcon = styled(Share)(({ theme }) => ({
  width: 22,
  height: 22,
  stroke: `${theme.palette.primary.main} !important`,
}));

const icons = {
  share: ShareIcon,
  download: DownloadIcon,
};

function ActionButton({ onClick, tooltip, action }) {
  const ActionIcon = icons[action];
  return (
    <IconButton onClick={onClick} tooltip={tooltip}>
      <ActionIcon />
    </IconButton>
  );
}

export default ActionButton;
