import React from "react";
import { useState } from "react";
import {
  Dialog,
  Grid,
  Typography,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import Lottie from "lottie-react";
import SuccessAnimation from "../../../resources/lotties/success.json";

const Base = styled(Grid)(({ theme }) => ({
  width: "100%",
  padding: "0px 20px 25px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  flex: 1,
}));

const Header = styled(Grid)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  margin: 10,
  padding: "5px",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "1.3rem",
  textAlign: "center",
}));

const CopyContainer = styled(Grid)(({ theme }) => ({
  width: "100%",
  padding: 5,
  backgroundColor: theme.palette.primary.background,
  borderRadius: 5,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const CopyLink = styled(Grid)(({ theme }) => ({
  maxWidth: "83%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  marginInlineStart: 10,
  fontSize: "0.9rem",
  opacity: 0.6,
}));

const CopyButton = styled(Grid)(({ theme }) => ({
  width: 35,
  height: 35,
  backgroundColor: "#FFF",
  borderRadius: 5,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  transition: "opacity 0.2s",
  "&:hover": {
    opacity: 0.6,
  },
  "&:active": {
    opacity: 0.3,
  },
}));

const LottieContainer = styled(Grid)(({ theme }) => ({
  width: 35,
  height: 35,
  backgroundColor: "#FFF",
  borderRadius: 5,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const CopyIcon = styled(ContentCopyRoundedIcon)(({ theme }) => ({
  width: 18,
  height: 18,
}));

const PrimaryButton = styled(Button)(({ theme }) => ({
  marginTop: 10,
  padding: "5px 30px",
  fontSize: "0.9rem",
  textTransform: "none",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
  "&:hover": {
    color: "#FFF",
    backgroundColor: theme.palette.primary.main,
  },
}));

const dialogPaperProps = {
  style: {
    width: "100%",
    maxWidth: 400,
    height: 256,
    margin: "20px 10px",
  },
};

function ShareDialog({ open, onClose, url }) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <Dialog open={open} PaperProps={dialogPaperProps}>
      <Header>
        <CloseButton onClick={onClose}>
          <CloseRoundedIcon />
        </CloseButton>
      </Header>
      <Base>
        <Title>Gallery Link</Title>
        <CopyContainer>
          <CopyLink>{url}</CopyLink>
          {!copied && (
            <Tooltip title="Copy link">
              <CopyButton onClick={copyToClipboard}>
                <CopyIcon />
              </CopyButton>
            </Tooltip>
          )}
          {copied && (
            <LottieContainer>
              <Lottie
                animationData={SuccessAnimation}
                loop={false}
                style={{ width: "25px", height: "25px" }}
              />
            </LottieContainer>
          )}
        </CopyContainer>
        <PrimaryButton variant="outlined" onClick={onClose}>
          Done
        </PrimaryButton>
      </Base>
    </Dialog>
  );
}

export default ShareDialog;
