import AppRoutes from "./components/AppRoutes";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./config/theme";
import { inject } from "@vercel/analytics";

inject();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppRoutes />
    </ThemeProvider>
  );
}

export default App;
