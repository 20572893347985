import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from '../../../Generic/Image';

const Root = styled(Grid)(({ theme }) => ({
	width: '60vw',
	maxWidth: 500,
	height: '60vw',
	maxHeight: 500,
	borderRadius: 30,
	boxShadow: '0 0 60px -10px #2c2c3d6e',
	[theme.breakpoints.down('lg')]: {
		maxWidth: 400,
		maxHeight: 400
	},
	[theme.breakpoints.down('md')]: {
		width: '80vw',
		height: '80vw',
		maxWidth: 800,
		maxHeight: 800
	}
}));

function GalleryCard({ gallery, setCoverImageLoaded }) {
	return (
		<Root>
			<Image src={gallery?.thumbnail} onLoaded={() => setCoverImageLoaded(true)} />
		</Root>
	);
}

export default GalleryCard;
