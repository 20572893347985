import React from 'react';
import { useState } from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as Logo } from '../../resources/svg/MeltzPhotographyFullLogo.svg';
import ActionButton from './NavbarComponents/ActionButton';
import ShareDialog from './NavbarComponents/ShareDialog';
import { SHARE_URL } from '../../config/env';
import { BACKEND_URL } from '../../config/env';
import { handleAnalyticsID } from '../../services/analyticService';

const Root = styled(Grid)(({ theme }) => ({
	width: '100%',
	minHeight: 60,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	backgroundColor: '#f8f7f7',
	position: 'sticky',
	top: 0,
	zIndex: 500
}));

const LogoWrapper = styled(Grid)(({ theme }) => ({
	margin: '1% 2%',
	textDecoration: 'none',
	cursor: 'pointer',
	[theme.breakpoints.down('sm')]: {
		margin: '1% 5%'
	}
}));

const MeltzPhotographyLogo = styled(Logo)(({ theme }) => ({
	width: 'auto',
	height: '2.2rem'
}));

const ActionContainer = styled(Grid)(({ theme }) => ({
	display: 'flex',
	margin: '0 1%',
	[theme.breakpoints.down('sm')]: {
		margin: '0 3%'
	}
}));

function Navbar({ gallery }) {
	const [openShareDialog, setOpenShareDialog] = useState(false);

	console.log('The gallery: ', gallery);

	const downloadGallery = async () => {
		handleAnalyticsID();
		await fetch(`${BACKEND_URL}/app/analytics/download/${gallery?._id}`, {
			method: 'POST',
			headers: { 'Content-type': 'application/json' },
			credentials: 'include'
		});
		const url = gallery?.downloadUrl;
		const link = document.createElement('a');
		link.href = url;
		link.target = '_self';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};
	return (
		<Root>
			<LogoWrapper onClick={() => window.open('https://www.meltzphotography.com', '_self')}>
				<MeltzPhotographyLogo />
			</LogoWrapper>
			<ActionContainer>
				<ActionButton
					onClick={downloadGallery}
					action="download"
					tooltip="Download gallery"
				/>
				<ActionButton
					onClick={() => setOpenShareDialog(true)}
					action="share"
					tooltip="Share gallery"
				/>
			</ActionContainer>
			<ShareDialog
				open={openShareDialog}
				onClose={() => setOpenShareDialog(false)}
				url={`${SHARE_URL}/${gallery?._id}`}
			/>
		</Root>
	);
}

export default Navbar;
