import React from "react";
import { styled } from "@mui/material/styles";
import { motion } from 'framer-motion'

const directions = {
  SLIDE_UP: 'up',
  SLIDE_RIGHT: 'right',
};

const motionStyles = {
  up: {
    initial: { y: '100%' },
    animate: { y: 0 },
  },
  right: {
    initial: { x: '-100%' },
    animate: { x: 0 },
  },
}

const Root = styled(motion.div)(({ theme }) => ({
  overflow: "hidden",
}));

const Wrapper = styled(motion.div, { shouldForwardProp: (prop) => !['direction', 'delay'].includes(prop) })(({ theme, direction, delay }) => ({
  display: "block",
}));

const RevealAnimate = ({ children, direction = directions.SLIDE_UP, delay = 0, rootSx }) => {
  return (
    <Root sx={rootSx} initial="hidden" whileInView="visible" viewport={{ once: true, amount: 0.5 }}>
      <Wrapper variants={{ visible: { ...(motionStyles[direction].animate), transition: { type: "spring", duration: 0.8, delay, bounce: 0.17 } }, hidden: motionStyles[direction].initial }} delay={delay}>{children}</Wrapper>
    </Root>
  );
};

export default RevealAnimate;