import React from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import GridGallery from "../Galleries/GridGallery";
import TextReveal from "../Animations/TextReveal";
import { ReactComponent as BackArrow } from "../../resources/svg/backarrow.svg";
import Navbar from "../Generic/Navbar";
import Footer from "../Generic/Footer";
import PageLoader from "../Generic/PageLoader";

const Root = styled(Grid)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#F2F0F0",
}));

const TitleContainer = styled(Grid)(({ theme }) => ({
  width: "100%",
  marginTop: "3rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    marginTop: "2rem",
    marginBottom: "-1rem",
  },
}));

const Title = styled(TextReveal)(({ theme }) => ({
  fontSize: "1.7rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.3rem",
  },
}));

const BackButton = styled(Grid)(({ theme }) => ({
  width: 45,
  height: 45,
  margin: "0 2vw",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  [theme.breakpoints.up("md")]: {
    margin: "0 3vw",
  },
}));

const ButtonPlaceholder = styled(Grid)(({ theme }) => ({
  width: 45,
  height: 45,
  margin: "0 2vw",
  [theme.breakpoints.up("md")]: {
    margin: "0 3vw",
  },
}));

const BackIcon = styled(BackArrow)(({ theme }) => ({
  width: 22,
  height: 22,
  stroke: `${theme.palette.primary.main} !important`,
  opacity: 0.3,
  [theme.breakpoints.down("sm")]: {
    width: 20,
    height: 20,
  },
}));

function Gallery({ gallery }) {
  return (
    <Root>
      {!gallery && <PageLoader />}
      {!!gallery && (
        <>
          <Navbar gallery={gallery} />
          <TitleContainer>
            {/* <RevealAnimate>
          <BackButton onClick={() => navigate("../galleries")}>
            <BackIcon />
          </BackButton>
        </RevealAnimate> */}
            <Title>{gallery?.title}</Title>
            {/* <ButtonPlaceholder /> */}
          </TitleContainer>
          <GridGallery images={gallery?.images} imageRatio={gallery?.ratio} />
          <Footer />
        </>
      )}
    </Root>
  );
}

export default Gallery;
