import React from 'react';
import { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useTheme } from '@mui/material/styles';

const Base = styled(motion.div)(({ theme }) => ({
	padding: '5px 30px',
	position: 'relative',
	width: 250
}));

const StyledButton = styled(Button)(({ theme }) => ({
	padding: '0',
	fontSize: '1.1rem',
	textTransform: 'none',
	color: theme.palette.primary.main,
	border: 'none',
	width: 250,
	'&:hover': {
		border: 'none',
		color: '#FFF',
		backgroundColor: theme.palette.primary.main
	}
}));

function PrimaryButton({ delay, radius = 4, key = 0, children, ...props }) {
	const textVariants = {
		visible: {
			opacity: 1,
			transition: { duration: 0.3, delay: delay }
		},
		hidden: { opacity: 0 }
	};
	const pathVariants = {
		visible: {
			strokeDashoffset: 0,
			transition: { duration: 1, delay: delay }
		},
		hidden: { strokeDashoffset: 460 }
	};

	const [parentWidth, setParentWidth] = useState(0);
	const [parentHeight, setParentHeight] = useState(0);

	const parentElement = document.getElementById(`button-parent-${key}`);

	useEffect(() => {
		if (parentElement) {
			setParentWidth(parentElement.offsetWidth);
			setParentHeight(parentElement.offsetHeight);
		}
	}, [key, parentElement?.offsetWidth, parentElement?.offsetHeight]);

	const theme = useTheme();
	const dimensionsDefined = !!parentWidth && !!parentWidth;

	return (
		<StyledButton variant="outlined" {...props}>
			<Base
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true, amount: 0.5 }}
				id={`button-parent-${key}`}
			>
				<motion.div variants={textVariants}>{children}</motion.div>
				<svg
					style={{
						position: 'absolute',
						left: 0,
						top: 0,
						width: '100%',
						height: '100%'
					}}
					viewBox={`0 0 ${parentWidth} ${parentHeight}`}
					fill="none"
					preserveAspectRatio="none"
				>
					{dimensionsDefined && (
						<motion.path
							d={`M${parentWidth / 2},1
						h${parentWidth / 2 - radius - 1}
						q${radius},0 ${radius},${radius}
						v${parentHeight - 2 * radius - 2}
						q0,${radius} -${radius},${radius}
						h-${parentWidth / 2 - radius - 1}
						`}
							stroke={theme.palette.primary.main}
							strokeDasharray="460"
							strokeDashoffset="460"
							strokeWidth="1"
							vectorEffect="non-scaling-stroke"
							viewport={{ once: true, amount: 0.5 }}
							variants={pathVariants}
						/>
					)}
					{dimensionsDefined && (
						<motion.path
							d={`M${parentWidth / 2},1
						h-${parentWidth / 2 - radius - 1}
						q-${radius},0 -${radius},${radius}
						v${parentHeight - 2 * radius - 2}
						q0,${radius} ${radius},${radius}
						h${parentWidth / 2 - radius - 1}
						`}
							stroke={theme.palette.primary.main}
							strokeDasharray="460"
							strokeDashoffset="460"
							strokeWidth="1"
							vectorEffect="non-scaling-stroke"
							variants={pathVariants}
						/>
					)}
				</svg>
			</Base>
		</StyledButton>
	);
}

export default PrimaryButton;
