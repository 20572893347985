import createTheme from "@mui/material/styles/createTheme";

const primary = {
  main: "#262f35",
  medium: "#36454F",
  light: "#b0b4b4",
  background: "#edf0f0",
  dark: "#202529",
  contrastText: "#F2F0F0",
};

const theme = createTheme({
  palette: {
    primary: primary,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1900,
    },
  },
  typography: {
    fontFamily: [
      "Plus Jakarta Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    allVariants: {
      color: primary.main,
    },
  },
});

export { theme };
