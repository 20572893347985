import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import GridGallery from '../Galleries/GridGallery';
import TextReveal from '../Animations/TextReveal';
import { ReactComponent as BackArrow } from '../../resources/svg/backarrow.svg';
import Navbar from '../Generic/Navbar';
import Footer from '../Generic/Footer';
import GalleryCard from './PageComponents/GalleryCover/GalleryCard';
import GalleryInfo from './PageComponents/GalleryCover/GalleryInfo';

const Root = styled(Grid)(({ theme }) => ({
	width: '100%',
	backgroundColor: '#F2F0F0'
}));

const Body = styled(Grid)(({ theme }) => ({
	width: '100%',
	minHeight: 'calc(100vh - 65px)',
	backgroundColor: '#F2F0F0',
	padding: 30,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	gap: '6vw',
	[theme.breakpoints.down('md')]: {
		padding: 15,
		justifyContent: 'start',
		flexDirection: 'column',
		gap: '0'
	}
}));

function GalleryCover({ gallery, setCoverImageLoaded }) {
	return (
		<Root>
			<Navbar gallery={gallery} />
			<Body>
				<GalleryInfo gallery={gallery} />
				<GalleryCard gallery={gallery} setCoverImageLoaded={setCoverImageLoaded} />
			</Body>
			<Footer />
		</Root>
	);
}

export default GalleryCover;
