import React from 'react';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextReveal from '../../../Animations/TextReveal';
import PrimaryButton from '../../../Generic/Buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';

const Root = styled(Grid)(({ theme }) => ({
	maxWidth: 500,
	padding: '1vw',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	[theme.breakpoints.down('lg')]: {
		maxHeight: 400,
		padding: '0'
	},
	[theme.breakpoints.down('md')]: {
		padding: '40px 0 50px'
	}
}));

const Title = styled(TextReveal)(({ theme }) => ({
	fontSize: '2.4rem',
	marginBottom: '2vh',
	[theme.breakpoints.down('lg')]: {
		fontSize: '2rem'
	},
	[theme.breakpoints.down('sm')]: {
		fontSize: '1.4rem'
	}
}));

const Description = styled(TextReveal)(({ theme }) => ({
	fontSize: '1.2rem',
	color: theme.palette.primary.medium,
	[theme.breakpoints.down('sm')]: {
		fontSize: '1rem'
	}
}));

const TextContainer = styled(Grid)(({ theme }) => ({
	flex: 1,
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	[theme.breakpoints.down('md')]: {
		alignItems: 'center'
	}
}));

const ButtonContainer = styled(TextReveal)(({ theme }) => ({
	margin: 'max(20%, 60px) 0',
	display: 'flex',
	alignItems: 'center',
	[theme.breakpoints.down('md')]: {
		margin: '40px 0 70px',
		justifyContent: 'center',
		marginBottom: 0
	}
}));

function GalleryInfo({ gallery }) {
	const navigate = useNavigate();

	return (
		<Root>
			<TextContainer>
				<Title>
					{gallery?.title}
				</Title>
				<Description delay={0.1}>{gallery?.description}</Description>
			</TextContainer>
			<ButtonContainer>
				<PrimaryButton delay={0.5} onClick={() => navigate('./view')}>
					View Gallery
				</PrimaryButton>
			</ButtonContainer>
		</Root>
	);
}

export default GalleryInfo;
