import React from 'react';
import { Grid, Modal, Grow } from '@mui/material';
import { styled } from '@mui/material/styles';
import Image from '../Generic/Image';
import { ReactComponent as BackArrow } from '../../resources/svg/backarrow.svg';
import { ReactComponent as Download } from '../../resources/svg/download.svg';
import IconButton from '../Generic/Buttons/IconButton';
import { getImageName } from '../../utils/imageUtils';

const Root = styled(Modal)(({ theme }) => ({
	'& .MuiBackdrop-root': {
		backgroundColor: '#000000dd'
	}
}));

const ModalBase = styled(Grid)(({ theme }) => ({
	width: '100vw',
	height: '100vh',
	display: 'flex',
	flexDirection: 'column'
}));

const ModalBody = styled(Grid)(({ theme }) => ({
	width: '100vw',
	flex: 1,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
}));

const ActionBar = styled(Grid)(({ theme }) => ({
	width: '100vw',
	padding: '2vh 15px 2vh',
	display: 'flex',
	background: 'linear-gradient(rgba(0,0,0,.65) 0%,transparent 100%)',
	justifyContent: 'space-between',
	alignItems: 'center',
	position: 'absolute',
	top: 0,
	left: 0
}));

const ImageWrapper = styled(Grid, {
	shouldForwardProp: (prop) => !['ratio'].includes(prop)
})(({ theme, ratio }) => ({
	maxWidth: '90vw',
	maxHeight: 'calc(100vh - 8vh - 70px)',
	objectFit: 'contain',
	borderRadius: '5px',
	position: 'relative',
	aspectRatio: ratio
}));

const SelectedImage = styled(Image, {
	shouldForwardProp: (prop) => !['ratio'].includes(prop)
})(({ theme, ratio }) => ({
	width: '100%',
	height: 'auto',
	aspectRatio: ratio
}));

const DownloadIcon = styled(Download)(({ theme }) => ({
	width: 20,
	height: 20,
	stroke: `#FFF !important`
}));

const BackIcon = styled(BackArrow)(({ theme }) => ({
	width: 26,
	height: 26,
	stroke: `#FFF !important`
}));

function SelectedImageModal({ image, open, onClose }) {
	/**
	 * Download image at original quality
	 */
	const downloadImage = async (e) => {
		e.stopPropagation();
		const response = await fetch(image?.original, {
			method: 'GET',
			headers: {
				Origin: 'https://app.meltzphotography.com'
			}
		});
		const blob = await response.blob();
		const url = URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.href = url;
		link.download = getImageName(image?.original);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<Root open={open}>
			<ModalBase onClick={onClose}>
				<ActionBar>
					<IconButton>
						<BackIcon />
					</IconButton>
					<IconButton
						tooltip="Download image"
						onClick={downloadImage}
						loaderProps={{ sx: { color: '#FFFFFF88' } }}
					>
						<DownloadIcon />
					</IconButton>
				</ActionBar>
				<ModalBody>
					<Grow in={open} {...(open ? { timeout: 200 } : {})}>
						<ImageWrapper ratio={image?.ratio} onClick={(e) => e.stopPropagation()}>
							<SelectedImage
								src={image?.src}
								fallback={image?.fallback}
								ratio={image?.ratio}
							/>
						</ImageWrapper>
					</Grow>
				</ModalBody>
			</ModalBase>
		</Root>
	);
}

export default SelectedImageModal;
