import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Root from "./Pages/Root";
import GalleryRoutes from "./GalleryRoutes";
import ScrollToTop from "../hooks/ScrollToTop";
import PageNotFound from "./Pages/PageNotFound";

function AppRoutes() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Root />} />
        <Route path="/gallery/:id/*" element={<GalleryRoutes />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
