import React from "react";
import { motion } from "framer-motion";
import { Grid } from "@mui/material";

const Skeleton = () => {
  return (
    <Grid
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "inherit",
        backgroundColor: "#E0E0E0",
      }}
      component={motion.div}
      animate={{
        opacity: [1, 0.6, 1],
      }}
      transition={{
        repeat: Infinity,
        duration: 1,
      }}
    />
  );
};

export default Skeleton;
