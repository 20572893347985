import React from 'react'
import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const Root = styled(Grid)(({ theme }) => ({
	width: '100%',
	minHeight: 60,
	padding: '3vh 0 4vh 0',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: '#eceaea',
	flexDirection: 'column',
	zIndex: 10000
}))

const CopyrightText = styled(Typography)(({ theme }) => ({
	color: theme.palette.primary.medium,
	fontSize: '0.75rem'
}))

function Footer() {
	return (
		<Root>
			<CopyrightText>Copyright&nbsp; ©&nbsp;2023&nbsp; Meltz Photography</CopyrightText>
			<CopyrightText>All rights reserved</CopyrightText>
		</Root>
	)
}

export default Footer
