/**
 * Gets an image name from the url
 * @param {string} url
 * @returns The image name
 */
export const getImageName = url => {
  console.log("The url: ", url);
  const dirs = url.split("/");
  return dirs?.[dirs?.length - 1];
};
