import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const Base = styled(Grid)(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

function Root() {
  useEffect(() => {
    window.open("https://www.meltzphotography.com", "_self");
  }, []);
  return <Base></Base>;
}

export default Root;
