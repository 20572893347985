import React from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";
import { ReactComponent as Logo } from "../../resources/svg/MeltzPhotographyIcon.svg";

const Root = styled(Grid)(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const LogoWrapper = styled(Grid)(({ theme }) => ({}));

const LogoIcon = styled(Logo)(({ theme }) => ({
  width: "auto",
  height: "5rem",
}));

function PageLoader() {
  return (
    <Root>
      <LogoWrapper
        component={motion.div}
        animate={{
          opacity: [0.4, 1, 0.4],
          scale: [1, 1.2, 1],
        }}
        transition={{
          repeat: Infinity,
          duration: 1.2,
        }}
      >
        <LogoIcon />
      </LogoWrapper>
    </Root>
  );
}

export default PageLoader;
