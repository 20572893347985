import React from 'react';
import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Gallery from './Pages/Gallery';
import GalleryCover from './Pages/GalleryCover';
import PageNotFound from './Pages/PageNotFound';
import { useParams } from 'react-router-dom';
import { BACKEND_URL } from '../config/env';
import PageLoader from './Generic/PageLoader';
import { handleAnalyticsID } from '../services/analyticService';
import { Grid } from '@mui/material';

function GalleryRoutes() {
	const { id } = useParams();
	const [gallery, setGallery] = useState();
	const [coverImageLoaded, setCoverImageLoaded] = useState(false);

	useEffect(() => {
		fetchGallery();
	}, []);

	const fetchGallery = async () => {
		handleAnalyticsID();
		const response = await fetch(`${BACKEND_URL}/app/galleries/data/${id}`, {
			method: 'GET',
			headers: {
				'Content-type': 'application/json'
			},
			credentials: 'include'
		});

		const galleryItem = await response?.json();

		setGallery({
			...galleryItem,
			images: galleryItem.images
				.map((image) => {
					return { ...image, src: image?.optimized };
				})
				.sort((a, b) => (a.src > b.src ? 1 : b.src > a.src ? -1 : 0))
		});
	};

	return (
		<>
			{(!gallery || !coverImageLoaded) && <PageLoader />}
			{!!gallery && (
				<Grid sx={{ display: coverImageLoaded ? 'block' : 'none' }}>
					<Routes>
						<Route
							path="/"
							element={
								<GalleryCover
									gallery={gallery}
									setCoverImageLoaded={setCoverImageLoaded}
								/>
							}
						/>
						<Route path="/view" element={<Gallery gallery={gallery} />} />
						<Route path="*" element={<PageNotFound />} />
					</Routes>
				</Grid>
			)}
		</>
	);
}

export default GalleryRoutes;
