import React from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Image from "../Generic/Image";
import { motion } from "framer-motion";

const Root = styled(Grid, {
  shouldForwardProp: prop => !["width", "ratio"].includes(prop),
})(({ theme, width, ratio }) => ({
  width: width,
  height: "auto",
  aspectRatio: ratio,
  padding: "1.7vw",
  [theme.breakpoints.up("md")]: {
    padding: "0.7vw",
  },
}));

const ImageWrapper = styled(motion.div)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: 5,
  position: "relative",
}));

const ImageShadow = styled("div")(({ theme }) => ({
  width: "inherit",
  height: "inherit",
  borderRadius: "inherit",
  boxShadow: "0 0 50px #dedcdc",
  zIndex: -5,
  position: "absolute",
  top: 0,
  left: 0,
}));

const variants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.5 },
  },
  hidden: { y: 40, opacity: 0 },
};

function GalleryImage({ key, src, fallback, width, ratio = 1.5, ...props }) {
  return (
    <Root key={key} width={width} ratio={ratio} {...props}>
      <ImageWrapper
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.05 }}
        variants={variants}
      >
        <Image src={src} fallback={fallback} />
        <ImageShadow />
      </ImageWrapper>
    </Root>
  );
}

export default GalleryImage;
